import React from "react"
import { graphql, Link } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`


const Footer = styled.footer`
  background: #000000;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`
const RevomeMarginWrapperVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
  div:first-child>div:first-child{
      padding-top: initial;
  }
  .react-player{
    position: relative!important;
    padding: 34.1% 0 0 0!important;
    iframe{
      position:absolute!important;
      top:0!important;
      left:0!important;
      width:100%!important;
      height:100%!important
    }
  }
    
`

const BioMarinVoxogo = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#E95040;",
        invert: {
          backgroundColor: "#A5A5A5",
        },
      }}
    >
      <>
        <Seo title="Voxogo It’s Go Time!" />
        <SectionContainer>
          <Container>
            <AccentHeader size="large">
              <WorkLink to="/">
                The Work
              </WorkLink>
            </AccentHeader>
            <PageTitle>It’s Go Time!</PageTitle>
            <Row>
              <Col md={4}>
                <AccentHeader>The Situation</AccentHeader>
                <SectionBody size="regular">
                  Achondroplasia is the most common form of dwarfism and is associated with chronic, life-threatening complications. And with the launch of VOXZOGO, there’s finally a therapy that targets the underlying cause of achondroplasia.<br/><br/>
 
Like many rare diseases, there was no blueprint for managing it and misinformation prevailed: many families thought medical intervention was cosmetic and many doctors lacked experience with the disease and community.<br/><br/>
 
To be successful, we needed doctors to see achondroplasia as a serious condition, recognize the value of treatment, and help families become open-minded regarding the option to treat with VOXZOGO. This meant creating a multichannel HCP campaign that could communicate this breakthrough and impart a sense of urgency to take action, while being empathetic and authentic to this vibrant, young community. 

                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="extraLarge" weight="bold">
                  To make a big impact on a condition characterized by short stature, we needed people to see that achondroplasia was about much more than height.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <RevomeMarginWrapperVideo>
          <VideoPlayer poster={
            () => <Img fluid={data.video1.childImageSharp.fluid} />
          } url="https://player.vimeo.com/video/668394066?h=67f7572521&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
        </RevomeMarginWrapperVideo>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#ffffff" }}>
            <Row>
              <Col md={4}>
                <AccentHeader>The Solution</AccentHeader>
                <SectionBody size="regular">
                  With these strategic imperatives at the forefront, we launched "It's Go Time," a multichannel campaign to show doctors that improving bone growth wasn't just possible, but could truly help children reach their full potential.<br/><br/>
 
We crafted our executions—stylistically and tonally—in a way that maintained a childlike charisma and spirit to ground them in the space and help them better understand their patient’s perspective, but delivered with the sophistication and science necessary to communicate the condition's seriousness. <br/><br/>
 
However, as the world's first FDA-approved therapy to target the underlying cause of achondroplasia, it's natural that doctors would have questions.
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody>
                  <h2>“How do I talk to families about therapy?”</h2>
To make it easy for doctors new to achondroplasia, we created a comprehensive Therapy Discussion Guide that equipped them with an appropriate lexicon to use, common family perspectives regarding therapy, and strategies to engage families in conversations about VOXZOGO.<br/><br/>
 
<h2>"What is it like being on therapy?”</h2>
To illuminate the real experience of starting VOXZOGO, we developed day-in-the-life patient testimonials in our signature HCP style. <br/><br/>
 
<em>Finally, to help answer the most exciting question…</em><br/><br/>
 
<h2>"How do I get my patients started?" </h2>
To ensure that no family or doctor feels the stress of starting a new therapy—especially one that involves injections—we developed an ecosystem of dosing materials for HCPs and patients to get familiar with VOXZOGO. Both the HCP and patient-friendly materials shared a similar, approachable design to create continuity between the patient and doctor. <br/><br/>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>



        <RevomeMarginWrapper >
          <VideoPlayer poster={
            () => <Img fluid={data.video2.childImageSharp.fluid} />
          } url="https://player.vimeo.com/video/796786269?h=43697032b6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
        </RevomeMarginWrapper>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginWrapper>
            <Img fluid={data.banner1.childImageSharp.fluid} />
          </RevomeMarginWrapper>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#ffffff" }}>
            <Row>
              <Col md={4}>
                <AccentHeader>The Success</AccentHeader>
                <SectionBody size="regular">
                  To help patients reach their full potential with VOXZOGO, the “It’s GO Time” campaign emphasizes the importance of starting and staying on therapy. As of March 31, 2021, VOXZOGO has treated an estimated 284 children, with only one discontinuation—making VOXZOGO a brand people can trust and believe in. This is a great success for a rare disease like achondroplasia that affects a total of 250,000 adults and children worldwide. <br/><br/>
 
Among these children, 201 are from countries outside the United States and 83 within the United States. This global reach is paving a new path forward for achondroplasia management and care for families around the world—giving BioMarin an even bigger chance to make meaningful strides in healthcare. Overall, BioMarin reported record first-quarter total revenues of $519 million, including a $20 million VOXZOGO contribution that allowed them to cross the $500 million mark. 
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody>
                  <strong>  <em>“The ‘It’s GO Time’ campaign provided healthcare professionals with a blueprint to initiate therapy and monitor growth for children with achondroplasia. Education had to start with doctors, in order to reach the most important piece of the puzzle—patients.”</em> – Senior Director, Global Brand Management, BioMarin </strong>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>


        <RevomeMarginWrapper noTopMargin noBottomMargin>
          <VideoPlayer poster={
            () => <Img fluid={data.video3.childImageSharp.fluid} />
          } url="https://player.vimeo.com/video/790575209?h=69bbe4ad4c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
        </RevomeMarginWrapper>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>


        <Footer></Footer>
      </>
    </ThemeProvider>
  </Layout>
)

export default BioMarinVoxogo

export const query = graphql`
  query {
    banner1: file(relativePath: { eq: "2023/biomarin-voxogo/banner1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    banner2: file(relativePath: { eq: "2023/biomarin-voxogo/banner2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image2: file(relativePath: { eq: "2023/go-time/image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    banner3: file(relativePath: { eq: "2023/biomarin-voxogo/banner3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    thumbs: file(relativePath: { eq: "2023/biomarin-voxogo/thumbs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    video1: file(relativePath: { eq: "2023/biomarin-voxogo/video1-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    video2: file(relativePath: { eq: "2023/biomarin-voxogo/video2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    video3: file(relativePath: { eq: "2023/biomarin-voxogo/video3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
